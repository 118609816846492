function checkOfferCookie(cookieName) {
  var cookie = getCookie(cookieName);
  if (cookie == 'undefined' || cookie == null || cookie == '') return ''
  var cookieValue = JSON.parse(cookie)['offer'];
  if (cookieValue == 'undefined' || cookieValue == null || cookieValue == '') return ''
  var button = document.getElementById('offer');
  if (button == 'undefined' || button == null || button == '') return ''
  if (cookieValue && parseInt(cookieValue) > 0) {
    button.disabled = true;
    button.classList = ['special_offer_price_box_disabled'];
    button.textContent = 'Уже в корзине';
  } else {
    button.disabled = false;
    button.classList = ['special_offer_price_box'];
  }
}

function getCookie(cName) {
  const name = cName + "=";
  const cDecoded = decodeURIComponent(document.cookie);
  const cArr = cDecoded.split('; ');
  let res;
  cArr.forEach(val => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  });
  return res;
}

window.onload = function(){
    const res = document.getElementsByName("product");
    const arr = [];
    res.forEach ( el => arr.push(el.id));
    arr.forEach(
        el => document.getElementById(el).addEventListener ("click", addToCart, false)
    );

    checkOfferCookie('the_cursed_cart');

    function addToCart() {
      const cart_cookie = 'the_cursed_cart';
      const cart_before = getCookie(cart_cookie);
      const cart_after = setCookie(cart_before, this.id);
      checkOfferCookie('the_cursed_cart');
      document.getElementById('counter').innerHTML = itemsAmount(cart_after).toString();
      if (cart_after[this.id] < 10) {
        iziToast.success({
          title: 'Набор успешно добавлен!',
          position: 'topCenter',
          timeout: 2000,
          drag: false,
          pauseOnHover: false,
          closeOnClick: true,
          displayMode: 'replace'
        });
      } else {
        iziToast.error({
          title: 'Добавлено максимальное количество для данного продукта!',
          position: 'topCenter',
          timeout: 1500,
          drag: false,
          pauseOnHover: false,
          closeOnClick: true,
          displayMode: 'replace'
        });
      }
    }

    function setCookie(cart, itemId) {
      const now = new Date();
      const time = now.getTime();
      const expireTime = time + 1000 * 60 * 60;
      now.setTime(expireTime);
      if (cart == null) {
        const parsedCart = {};
        parsedCart[itemId] = 1;
        document.cookie = `the_cursed_cart=${JSON.stringify(parsedCart)}; expires=${now.toUTCString()};`;
        checkOfferCookie('the_cursed_cart');
        return parsedCart;
      }
      const parsedCart = JSON.parse(cart)
      if (parsedCart[itemId] == null) {
        parsedCart[itemId] = 1;
      } else {
        if (parsedCart[itemId] >= 10) {
            parsedCart[itemId] = 10;
        } else {
            parsedCart[itemId] = parsedCart[itemId] + 1;
        }
      }
      document.cookie = `the_cursed_cart=${JSON.stringify(parsedCart)}; expires=${now.toUTCString()}; path=/`;
      checkOfferCookie('the_cursed_cart');
      return parsedCart;
    }

    function itemsAmount(cart) {
        let counter = 0;
        for (let i in cart){
            counter += parseInt(cart[i]);
        }
        return counter;
    }
};
